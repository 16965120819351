<template>
    <div class="k-flex-col w-100">
        <div class="text-center mb-2">
            <h1>CHANGEZ VOTRE MOT DE PASSE</h1>
        </div>
        <div class="k-form-fp">
            <FormChangePassword />
        </div>
    </div>
</template>

<script>
import FormChangePassword from "@/components/misc/form/FormChangePassword.vue";
export default {
    name: "Connexion",
    components: {
        FormChangePassword,
    },
};
</script>
