<template>
    <div class="k-flex-col">
        <b-form-group>
            <label><b>Ancien mot de passe</b></label>
            <b-form-input
                v-model="oldPassword"
                type="password"
                placeholder="Mot de passe"
                autocomplete="nope"
            ></b-form-input>
        </b-form-group>

        <b-form-group>
            <label><b>Nouveau mot de passe</b></label>
            <b-form-input
                v-model="newPassword"
                type="password"
                placeholder="Mot de passe"
                autocomplete="new-password"
            ></b-form-input>
            <b-form-invalid-feedback :state="isNewPasswordValid">
                Votre mot de passe doit contenir au moins 8 caractères
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label><b>Confirmation du nouveau mot de passe</b></label>
            <b-form-input
                v-model="newPasswordConfirmation"
                type="password"
                placeholder="Mot de passe"
                autocomplete="nope"
            ></b-form-input>
            <b-form-invalid-feedback :state="isNewPasswordMatching">
                Les mots de passe ne correspondent pas
            </b-form-invalid-feedback>
        </b-form-group>

        <b-button
            class="align-self-center mb-2"
            variant="kalkin-2"
            @click="update()"
            :disabled="!isValid"
        >
            <b-icon-check-circle /> Valider le nouveau mot de passe
        </b-button>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            oldPassword: "",
            newPassword: "",
            newPasswordConfirmation: "",
        };
    },

    computed: {
        isNewPasswordValid() {
            return this.newPassword.length === 0 || this.newPassword.length > 7;
        },

        isNewPasswordMatching() {
            return this.newPassword === this.newPasswordConfirmation;
        },

        isValid() {
            return (
                this.oldPassword.length > 0 &&
                this.newPassword.length > 7 &&
                this.isNewPasswordMatching
            );
        },
    },

    methods: {
        async update() {
            if (this.isValid) {
                if (
                    await this.$store.dispatch("user/updatePassword", {
                        oldPassword: this.oldPassword,
                        password: this.newPassword,
                    })
                ) {
                    this.$store.dispatch("user/logout");
                    this.$router.push({ name: "Login" });
                }
            }
        },
    },
};
</script>
